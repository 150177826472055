<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
          <li class="fd-app-breadcrumb-item">
            <a
              href="/minhas-plataformas"
              class="aluno_font_color"
              @click.prevent="$router.push('/minhas-plataformas')"
            >Minhas Plataformas</a>
          </li>
          <li class="fd-app-breadcrumb-item">
            <a
              class="aluno_font_color"
              :href="'/plataforma/' + $route.params.id_plataforma"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
            >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
          </li>
          <li class="fd-app-breadcrumb-item">
            <a
              class="aluno_font_color"
              :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
            >Modo secretaria</a>
          </li>
          <li class="fd-app-breadcrumb-item active">
            <a
              class="aluno_font_color"
              href="#"
            >Documentos autenticados</a>
          </li>
        </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-5 container">
        
        <!-- Conteúdo Principal Relatório -->
        <transition name="slide-fade">
          <div>
            <!-- fd-app-welcome -->
            <section class="fd-app-welcome text-left">
              <h1 class="aluno_font_color">
                Documentos 
                <span>Autenticados</span>
              </h1>
              <img :src="require('@/assets/images/form-effect-01.png')">
            </section>
            <!-- /fd-app-welcome -->
            <!-- <div v-if="$store.state.fastCarregando">
              <div class="col-12 text-center">
                <b-icon
                  icon="gear-fill"
                  animation="spin"
                /> Carregando dados
              </div>
            </div> -->
            <!-- <section v-else>
              <div class="row"> -->
                <!-- <form class="form col-sm-12 col-md-12 col-lg-4">
                  <label class="text-secondary mb-0 mt-2"><small>Status:</small></label>
                  <select
                  class="form-control form-control-sm border border-primary text-primary"
                  v-model="fastDocumentos.filtros.filtroStatus">
                    <option value="">
                      Todos
                    </option>
                    <option value=true>
                      Assinado
                    </option>
                    <option value=false>
                      Não assinado
                    </option>
                  </select>
                </form>
                <form class="form col-sm-12 col-md-12 col-lg-4">
                  <label class="text-secondary mb-0 mt-2"><small>Gateway de assinatura:</small></label>
                  <select
                  class="form-control form-control-sm border border-primary text-primary"
                  v-model="fastDocumentos.filtros.filtroGateway">
                    <option value="">
                      Todos
                    </option>
                    <option v-for="gateway in fastDocumentos.gatewaysAssinaturas" :key="gateway.id_assinatura_gateway" value="gateway.id_assinatura_gateway">
                      {{ gateway.nome_gateway }} <span v-if="!gateway.disponivel">(Inativo)</span>
                    </option>
                  </select>
                </form>
                <form class="form col-sm-12 col-md-12 col-lg-4">
                  <label class="text-secondary mb-0 mt-2"><small>Categoria Documento:</small></label>
                  <select
                  class="form-control form-control-sm border border-primary text-primary"
                  v-model="fastDocumentos.filtros.filtroCategoriaDoc">
                    <option value="">
                      Todos
                    </option>
                    <option v-for="categoria in fastDocumentos.categoriasDocumentos" :key="categoria.id_assinatura_plataforma_documento_categoria" :value="categoria.id_assinatura_plataforma_documento_categoria">
                      {{ categoria.nome_assinatura_plataforma_documento_categoria }}
                    </option>
                  </select>
                </form>
                <form class="form col-sm-12 col-md-12 col-lg-4">
                  <label class="text-secondary mb-0 mt-2"><small>Tipo Documento:</small></label>
                  <select
                  class="form-control form-control-sm border border-primary text-primary"
                  v-model="fastDocumentos.filtros.filtroTipoDoc">
                    <option value="">
                      Todos
                    </option>
                    <option v-for="tipo in fastDocumentos.tiposDocumentos" :key="tipo.id_assinatura_documento_tipo" :value="tipo.id_assinatura_documento_tipo">
                      {{ tipo.nome_assinatura_documento_tipo }}
                    </option>
                  </select>
                </form>
                <form class="form col-sm-12 col-md-12 col-lg-4">
                  <label class="text-secondary mb-0 mt-2"><small>CPF de um signatário:</small></label>
                  <input
                  type="text"
                  v-model="fastDocumentos.filtros.filtroCPF"
                  class="form-control form-control-sm border border-primary text-primary"
                  />
                </form>
                <form class="form col-sm-12 col-md-12 col-lg-4">
                  <label class="text-secondary mb-0 mt-2 d-flex justify-content-between">
                    <small class="pl-0 ml-0 pl-0 col-8"><b>Data do documento:</b></small>
                    <small
                    class="col-4 text-right mr-0 pr-0"
                    role="button"
                    v-if="fastDocumentos.filtros.filtroDataInicial || fastDocumentos.filtros.filtroDataFinal" 
                    @click="fastDocumentos.filtros.filtroDataInicial = '', fastDocumentos.filtros.filtroDataFinal = ''"
                    >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                  </label>
                  <div class="d-flex">
                    <b-form-datepicker 
                    class="align-self-start"
                    size="sm"
                    v-model="fastDocumentos.filtros.filtroDataInicial" 
                    locale="pt-BR"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                    label-no-date-selected="Selecionar data"
                    placeholder="Selecionar data"
                    label-help="Use o cursor para selecionar"
                    ></b-form-datepicker>
                    <small class="align-self-start pt-1 px-2">até</small>
                    <b-form-datepicker 
                    class="align-self-start"
                    size="sm"
                    v-model="fastDocumentos.filtros.filtroDataFinal"
                    locale="pt-BR"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                    label-no-date-selected="Selecionar data"
                    placeholder="Selecionar data"
                    label-help="Use o cursor para selecionar"
                    ></b-form-datepicker>
                  </div>              
                </form> -->
              <!-- </div> -->
              <!-- <div class="row d-flex justify-content-between">
                <div class="text-left col-lg-6 col-md-6 col-sm-6 mb-3 mt-5">
                  <b-button @click="showModal('modalNovoDocumento')" variant="primary btn-sm text-capitalize rounded mr-3">+ Novo Documento</b-button>
                </div>
                <div class="text-right col-lg-6 col-md-6 col-sm-6 mb-3 mt-5">
                  <b-button @click="limpaFiltros()" variant="custom-outline-primary btn-sm text-capitalize rounded mr-3">Limpar Filtros</b-button>
                  <b-button @click="getDocumentos()" variant="custom-primary btn-sm text-capitalize rounded">Filtrar</b-button>                       
                </div>
              </div> -->
            <!-- </section> -->

          <div class="row mt-3">
            <div class="col-12 table-responsive">
              <table class="table table-sm text-nowrap table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th class="text-center">
                      <small>ID</small>
                    </th>
                    <th class="text-center">
                      <small>CPF(s) Signatário(s)</small>
                    </th>
                    <th class="text-center">
                      <small>Data Docto.</small>
                    </th>
                    <th class="text-center">
                      <small>Assinado</small>
                    </th>
                    <th class="text-center">
                      <small>Gateway</small>
                    </th>
                    <th class="text-center">
                      <small>Tipo</small>
                    </th>
                    <th class="text-center">
                      <small>Categoria</small>
                    </th>
                    <th class="text-center">
                      <small>Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="$store.state.fastCarregando">
                  <tr>
                    <td
                      colspan="12"
                      class="text-center"
                    >
                    <b-icon icon="gear-fill" animation="spin"/> Carregando 
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="fastDocumentos.resultados.length">
                  <tr v-for="d in fastDocumentos.resultados" :key="d.id_assinatura_documento_plataforma">
                    <td class="align-middle text-center">
                      <small>#{{ d.id_assinatura_plataforma_documento }}</small>                                  
                    </td>
                    <td class="align-middle text-center">
                      <small v-for="u in d.fast_assinatura_plataforma_documento_usuario" 
                              :key="u.id_assinatura_plataforma_documento_usuario" 
                              class="btn-novo btn-sm btn-secondary mx-1" 
                              v-b-tooltip.hover.up.html="{title: criaTooltip(u), delay: {show: 1000}}" 
                              role="button"
                      >
                      {{ u.cpf_usuario }}
                      </small>                                  
                    </td>
                    <td class="align-middle text-center"
                      v-b-tooltip.hover.up.html="{title: criaTooltip(), delay: {show: 1000}}"
                      role="button"
                    >
                        <small>{{ new Date(d.data_registro_documento).toLocaleDateString("pt-br") }}</small>                               
                    </td>
                    <td class="align-middle text-center">
                      <small v-if="d.assinado" class="text-success">Assinado</small>                             
                      <small v-else class="text-danger">Não assinado</small>                             
                    </td>
                    <td class="align-middle text-center">
                      <small>{{ getNomeGateway(d.id_assinatura_gateway) }}</small>                                 
                    </td>
                    <td class="align-middle text-center">
                      <small v-if="d.id_assinatura_documento_tipo">{{ getNomeTipoDocumento(d.id_assinatura_documento_tipo) }}</small>                                 
                      <small v-else>Tipo não informado</small>                                 
                    </td>
                    <td class="align-middle text-center"
                      v-b-tooltip.hover.up.html="{title: criaTooltip(), delay: {show: 1000}}"
                      role="button"
                    >
                      <small v-if="d.id_assinatura_plataforma_documento_categoria">{{ getNomeCategoriaDocumento(d.id_assinatura_plataforma_documento_categoria)}}</small>
                      <small v-else>Não especificado</small>                                                                  
                    </td>
                    <td class="align-middle text-center">
                      <small>
                        <a class="btn-novo btn-primary" href="#" @click="exibeModalDetalhesDocumento(d.id_assinatura_plataforma_documento)">Detalhes</a>
                      </small>                                  
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="12" class="text-center">
                      Nenhum registro encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="my-3">
                Mostrando {{ fastSecretariaPaginacao.range_start }} até {{ fastSecretariaPaginacao.range_end }} de um total de {{ fastSecretariaPaginacao.total_results }}
              </div>
              <div class="row my-1">
                <div class="text-center mx-auto">
                  <b-pagination
                  v-model="fastSecretariaPaginacao.fastPage"
                  :total-rows="fastSecretariaPaginacao.total_results"
                  :per-page="fastSecretariaPaginacao.fastPageSize"
                  @change="getDocumentos"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
        <!-- /Conteúdo Principal Relatório -->

        <!-- fd-app-meus-cursos -->
        <div class="mt-5 mb-5">
          <a
            id="btn_voltar_modo_admin"
            class="btn btn-red-hollow"
            :href="
              '/plataforma/' + $route.params.id_plataforma + '/secretaria'
            "
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
          >
            <b-icon-arrow-return-left />
            Voltar
          </a>
        </div>
      </div>
      <!--modals-->

      <modal
      name="modalNovoDocumento"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthLg"
    >
      <div v-if="$store.state.fastCarregando" class="row">
        <div class="text-center my-5 mx-auto">
          <b-icon icon="gear-fill" animation="spin" /> Carregando
        </div>
      </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Novo Documento</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalNovoDocumento') ">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12 p-3 text-center">
          <fast-cria-documentos 
              :id_pessoa="parseInt($route.params.id_pessoa_aluno)" 
              :id_plataforma="parseInt($route.params.id_plataforma)" 
              />        
        </div>
      </div>
    </modal>

    <modal
      name="modalDetalhesDocumento"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthLg"
    >
      <div v-if="$store.state.fastCarregando" class="row">
        <div class="text-center my-5 mx-auto">
          <b-icon icon="gear-fill" animation="spin" /> Carregando
        </div>
      </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Detalhes</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalDetalhesDocumento') ">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12 p-3 text-center">
          <fast-consulta-documentos-assinatura 
              :id_plataforma="parseInt($route.params.id_plataforma)"
              :id_assinatura_matricula="parseInt(fastDocumentos.doc_id)" 
              />
        </div>
      </div>
    </modal>
    </div>
  </div>
</template>
  
<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import FastCriaDocumentos from "../components/FastCriaDocumentos";
import FastConsultaDocumentosAssinatura from "../components/FastConsultaDocumentosAssinatura";

export default {
  name: "HomeInternoSecretariaAssinaturaDeDocumentos",
  components: {
    Pagination,
    FastCriaDocumentos,
    FastConsultaDocumentosAssinatura
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastModalSucesso: this.$store.state.fastModalSucesso,
      fastDocumentos: {
        doc_id: '',
        resultados: [],
        gatewaysAssinaturas: [],
        categoriasDocumentos: [],
        tiposDocumentos: [],
        filtros: {
          filtroStatus: '',
          filtroGateway: '',
          filtroCategoriaDoc: '',
          filtroTipoDoc: '',
          filtroCPF: '',
          filtroDataInicial: '',
          filtroDataFinal: new Date(),
        }
      },
      fastSecretariaPaginacao: {
        fastPage: 1,
        fastPageSize: 10,
        success: false,
        range_start: 0,
        range_end: 0,
        total_results: 0,
        error: null,
        description: null,
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.$store.state.fastCarregando = true;
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          this.$store.state.fastControlaComponentes.showHideExcluiDocumentoAutenticacao = true
          this.getGatewaysAssinatura()
          this.getTiposDocumento()
          this.getCategoriasDeDocumento()
        }).then(() => {
          this.getDocumentos()
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  computed: {},
  watch: {},
  methods: {
    async getDocumentos () {
      this.$store.state.fastCarregando = true;
      if(this.fastDocumentos.filtros.filtroDataInicial) {
        this.fastDocumentos.filtros.filtroDataInicial = new Date(this.fastDocumentos.filtros.filtroDataInicial).toISOString()
      }
      if(this.fastDocumentos.filtros.filtroDataFinal) {
        this.fastDocumentos.filtros.filtroDataFinal = new Date(this.fastDocumentos.filtros.filtroDataFinal).toISOString()
      }
      this.$nextTick(() => {	
        this.promiseGetFastApi("api/fast_assinatura_plataforma_documento/lista_filtro", `id_plataforma=${this.$route.params.id_plataforma}&page_number=${this.fastSecretariaPaginacao.fastPage}&page_size=${this.fastSecretariaPaginacao.fastPageSize}&id_assinatura_documento_tipo=${this.fastDocumentos.filtros.filtroTipoDoc}&id_assinatura_plataforma_documento_categoria=${this.fastDocumentos.filtros.filtroCategoriaDoc}&data_start=${this.fastDocumentos.filtros.filtroDataInicial}&data_end=${this.fastDocumentos.filtros.filtroDataFinal}&assinado=${this.fastDocumentos.filtros.filtroStatus}`)
        .then(res => {
          if (res) {
            this.fastDocumentos.resultados = res.data;
            this.fastSecretariaPaginacao.range_start = res.range_start
            this.fastSecretariaPaginacao.range_end = res.range_end
            this.fastSecretariaPaginacao.total_results = res.total_results
            this.$store.state.fastCarregando = false;
          } else {
            this.exibeToasty("Não foram encontrados documentos.", "error")
            this.$store.state.fastCarregando = false;
          }
        }).catch(e => {
          console.log(e);
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        })
      })
    },
    async getGatewaysAssinatura(){
      this.$store.state.fastCarregando = true;
      await this.promiseGetFastApi("api/fast_assinatura_gateway/lista_disponiveis", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then((res) => {
        if (res) {
          this.fastDocumentos.gatewaysAssinaturas = res
          // console.log("gateways", this.fastDocumentos.gatewaysAssinaturas)
        }
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        this.exibeToasty(e, "error")
        console.log(e)
        this.$store.state.fastCarregando = false;
      })
    },
    async getTiposDocumento(){
      this.$store.state.fastCarregando = true;
      await this.promiseGetFastApi("api/fast_assinatura_documento_tipo/lista_todos")
      .then((res) => {
        if (res) {
          this.fastDocumentos.tiposDocumentos = res
          // console.log("tiposdoc", this.fastDocumentos.tiposDocumentos)
        }
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        this.exibeToasty(e, "error")
        console.log(e)
        this.$store.state.fastCarregando = false;
      })
    },
    async getCategoriasDeDocumento(){
      this.$store.state.fastCarregando = true;
      await this.promiseGetFastApi("api/fast_assinatura_plataforma_documento_categoria/lista_id", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then((res) => {
        if (res) {
          this.fastDocumentos.categoriasDocumentos = res
          // console.log("cats.doc", this.fastDocumentos.categoriasDocumentos)
        }
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        this.exibeToasty(e, "error")
        console.log(e)
        this.$store.state.fastCarregando = false;
      })
    },
    getNomeGateway (id_gateway) {
      try {
        if (id_gateway) {
          const gateway = [...this.fastDocumentos.gatewaysAssinaturas].find(g => g.id_assinatura_gateway === id_gateway) 
            return gateway.nome_gateway ? gateway.nome_gateway : null;
        }
      } catch (error) {
        console.log(error, "error")
      }
    },
    getNomeTipoDocumento (id_tipo_documento) {
      try {
        if (id_tipo_documento) {
          const tipo_documento = [...this.fastDocumentos.tiposDocumentos].find(t => t.id_assinatura_documento_tipo === id_tipo_documento) 
            return tipo_documento.nome_assinatura_documento_tipo ? tipo_documento.nome_assinatura_documento_tipo : null;
        }
      } catch (error) {
        console.log(error, "error")
      }
    },
    getNomeCategoriaDocumento (id_categoria_documento) {
      try {
        if (id_categoria_documento) {
          const categoria_documento = [...this.fastDocumentos.categoriasDocumentos].find(c => c.id_assinatura_plataforma_documento_categoria === id_categoria_documento) 
            return categoria_documento.nome_assinatura_plataforma_documento_categoria ? categoria_documento.nome_assinatura_plataforma_documento_categoria : null;
        }
      } catch (error) {
        console.log(error, "error")
      }
    },
    limpaFiltros() {
      this.fastDocumentos.filtros.filtroTipoDoc = ''
      this.fastDocumentos.filtros.filtroCategoriaDoc = ''
      this.fastDocumentos.filtros.filtroDataInicial = ''
      this.fastDocumentos.filtros.filtroDataFinal = new Date()
      this.fastDocumentos.filtros.filtroStatus = ''
      this.getDocumentos()
    },
    exibeModalDetalhesDocumento(doc_id){
      this.fastDocumentos.doc_id = doc_id
      this.showModal('modalDetalhesDocumento')
    },
    criaTooltip(u){
      if (u) {
        return `<div class="text-left">
                  Nome: ${u.nome_usuario ? u.nome_usuario : 'Não informado'}<br>
                  Assinou: ${u.assinou ? 'Sim' : 'Não'}<br>
                  Visualizou: ${u.visualizou ? 'Sim' : 'Não'}<br>
                  Envio Email: ${u.enviado_email ? 'Sim' : 'Não'}<br>
                  Envio SMS: ${u.enviado_sms ? 'Sim' : 'Não'}<br>
                  Envio WApp: ${u.enviado_whatsapp ? 'Sim' : 'Não'}<br>
                </div>`;
      }
    }
  },
};
</script>
  
<style scope>
.container{
  max-width: 1280px;
}
.date-picker-filtro  {
  text-align: center;
}
.btn-custom-primary{
  background-color: #007bff!important;
  color: #fff!important;
}
.btn-custom-outline-primary{
  background-color: #fff!important;
  color: #007bff!important;
  border:1px solid #007bff!important;
}
.dropdown-menu{
  font-size: 12px;
}

</style>
  